import React from 'react';
import './Intro.css';
import EmailBtn from '../../components/EmailBtn/EmailBtn';

const Intro = () =>
    <div className={"Intro"}>
        <div><h3>Greetings! I am...</h3></div>
        <div className={"MyName"}><h1>Leo Lee</h1></div>
        <div><h2>I am a Software Engineer / Programmer</h2></div>
        <div><p>This site is built for documenting my learning progress in programming and to share some of the ideas and projects that I've made. Welcome!</p></div>
        <EmailBtn>Get In Touch!</EmailBtn>
    </div>

export default Intro;