import　React from 'react';
import './About.css';

const About = () =>
    <div id="About" className={"About"}>
        <div className={"SelfIntro"}>
            <h2>About me...</h2>
            <p>Currently, I work as a software engineer in UK.</p>
            <p>I am capable of full stack development and now mainly focus on Java programming.</p>
            <p>Also, I work around with Devops tools for project deployment and operations.</p>
            <p>I have started to create some individual side projects since I got into this career and developed a deeper interest in it.</p>
            <p>My technical skills are not perfect but I will try improving!</p>
            <p>Aside from coding, I have many hobbies. Playing beach volleyball and drums are my favourites! Tell me if that is your thing too :)</p>
        </div>
        <div className={"ProfileImageContainer"}>
            <img className={"ProfileImage Mobile"} src="/assets/images/profileImageMobile.jpg" alt=""/>
            <img className={"ProfileImage Normal"} src="/assets/images/profileImageNormal.jpg" alt=""/>
        </div>
    </div>

export default About;