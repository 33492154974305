import React, { useRef, useState } from 'react'
import './App.css';
import Navbar from './components/Navbar/Navbar'
import HomeBtn from './components/HomeBtn/HomeBtn';
import SocialMediaBar from './components/SocialMediaBar/SocialMediaBar';
import Intro from './contents/Intro/Intro';
import About from './contents/About/About';
import Experience from './contents/Experience/Experience';
import Contact from "./contents/Contact/Contact";
import $ from "jquery";

function App() {
  const inputRef = useRef();

  var [prevScrollPos, setPrevScrollPos] = useState(0);

  function onScrollListener() {
      var currentScrollPos = inputRef.current.scrollTop;
      var offsetHeight = inputRef.current.offsetHeight;
      if (currentScrollPos > offsetHeight/2) {
          if (currentScrollPos < prevScrollPos) {
              $(".App-header").css({"top": "0"});
          } else {
              $(".App-header").css({"top": "-10%"});
          }
      }
      setPrevScrollPos(currentScrollPos);
  }

  return (
    <div ref={inputRef} className="App" onScroll={onScrollListener}>
      <header className="App-header">
          <HomeBtn/>
          <Navbar/>
      </header>
      <div className={"Body"}>
          <SocialMediaBar additionalClass="SideBar"/>
          <div className={"Content"}>
              <Intro/>
              <About/>
              <Experience/>
              <Contact/>
          </div>
      </div>
    </div>
  );
}

export default App;
