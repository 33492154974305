import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './Navbar.css';
import Navitem from '../Navitem/Navitem';

const Navbar = () =>
    <div className={"Navbar"}>
        <Router>
            <Navitem item="About" tolink="#About" ></Navitem>
            <Navitem item="Experience" tolink="#Experience"></Navitem>
            <Navitem item="Projects" tolink="#Projects"></Navitem>
            <Navitem item="Contact" tolink="#Contact"></Navitem>
        </Router>
    </div>
    
export default Navbar;