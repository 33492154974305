import React from 'react';
import './Navitem.css';
import { HashLink as Link } from 'react-router-hash-link';

const Navitem = (props) =>
    <div className={"Navitem"}>
        <Link to={props.tolink}>
            {props.item}
        </Link>
    </div>

export default Navitem