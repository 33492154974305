import　React from 'react';
import './Experience.css';
import PageBox from "../../components/PageBox/PageBox";

const Experience = () => {
    const experiences = [
        {
            name: "Varmeego Limited",
            url: "https://varmeego.com/",
            title: "Analyst Programmer",
            from: "2020 Jul",
            to: "Present",
            duties: [
                "Worked for client in retail industry",
                "Responsible for the development of eCommerce-related application",
                "Updated and maintain eShop API and functions",
                "Provided production support for the eShop website",
                "Performed load test and performance test"
            ],
            projects: [
                {
                    name: "Clickstream ETL Application",
                    details: [
                        "Developed and shipped Java application which extract and transform data into useful information for marketing purposes",
                        "Extracted eShop websites' clickstream data which are stored in Google Analytics and BigQuery",
                        "Deployed and update application by Docker",
                        "Hosted and maintained application through Microsoft Azure Cloud Services"
                    ]
                },
                {
                    name: "Performance and Load Testing",
                    details: [
                        "Designed a set of plans to perform load test and performance test for eCommerce websites and app",
                        "Performed the test and collected corresponding results",
                        "Reported results for further operation"
                    ]
                },
                {
                    name: "Data Migration",
                    details: [
                        "Performed data migration for SAP Hybris database",
                        "Designed and developed stored procedures for the whole data migration process",
                        "Created impex for data patching"
                    ]
                }
            ]
        },
        {
            name: "Hong Kong Economic Times",
            url: "https://www.hket.com/",
            title: "Programmer",
            from: "2018 SEP",
            to: "2020 Jun",
            duties: [
                "Responsible for the development and maintenance of web application",
                "Implemented and revamped design layout for the company’s news websites",
                "Developed and maintained company’s content management system",
                "Developed and updated data model for news contents"
            ],
            projects: [
                {
                    name: "Fitness / Health Web Application",
                    details: [
                        "Implemented UX and layout design for a fitness tracker web application",
                        "Designed and created data model for fitness data",
                        "Constructed controller to handle web pages access",
                        "Implemented functions to access internal endpoint to store and manage user information from database"
                    ]
                },
                {
                    name: "Content Management System (CMS)",
                    details: [
                        "Rebuilt company’s CMS for managing articles",
                        "Developed MVC for process data and control web view",
                        "Set up endpoint calls to store article’s information to database"
                    ]
                },
                {
                    name: "Company Website Enhancement"
                },
                {
                    name: "Google Sitemap / RSS Generation Job",
                    details: [
                        "Designed and constructed data model to store articles data so that it can be used to transform xml files through JAXB",
                        "Implemented schedules to ensure articles information in xml files are up-to-date"
                    ]
                },
                {
                    name: "Articles Reindexing Job",
                    details: [
                        "Developed program to access API for managing articles data according to requirements"
                    ]
                }
            ]
        },
        {
            name: "Portalvision Limited",
            url: "http://www.portalvision.com/",
            title: "Programming Part Time",
            from: "2018 Feb",
            to: "2018 Jul",
            duties: [
                "Designed and developed customer relationship management system",
                "Designed and developed interface for web pages",
                "Developed and configured database connection with the system"
            ]
        },
        {
            name: "Brilliant Circle Group",
            url: "http://bcghk.cn",
            title: "Programming Part Time",
            from: "2017 Oct",
            to: "2018 Feb",
            duties: [
                "Developed features and constructed infrastructure for different online platforms to provide automation and analytics for factory management, logistics and targeted marketing",
                "Assisted in building dynamic, interactive desktop and mobile web interfaces for online systems"
            ]
        },
        {
            name: "Aristo Educational Pressed Limited",
            url: "https://www.aristo.com.hk/",
            title: "Summer IT Intern",
            from: "2017 Jun",
            to: "2017 Aug",
            duties: [
                "Designed and produced electronic textbook",
                "Engaged in websites developing tasks",
                "Performed data processing duties"
            ]
        }
    ];

    return (
        <div id="Experience" className={'Experience'}>
            <PageBox contents={experiences}>
                {
                    experiences.map((experience, index) => {
                        const elementId = "content-" + index;
                        const companyName = experience.url != null ? <a href={experience.url} target="_blank" rel="noreferrer">{experience.name}</a> : experience.name
                        return (
                            <div key={index} id={elementId} className={"PageBoxContent"}>
                                <div className={"Title"}>{experience.title} @ <u>{companyName}</u></div>
                                <div className={"Period"}>{experience.from.toUpperCase()} - {experience.to.toUpperCase()}</div>
                                <div className={"Duties"}>
                                    <ul>
                                        {
                                            experience.duties.map((duty, index) => {
                                                return ( <li key={index}>{ duty }</li> )
                                            })
                                        }
                                    </ul>
                                </div>
                                { experience.projects != null &&
                                    <div className={"Projects"}>
                                        <h3>Projects:</h3>
                                        <ul>
                                            {
                                                experience.projects.map((project, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className={"ProjectTitle"}>{project.name}</div>
                                                            { project.details != null &&
                                                                    <ul className={"ProjectDetails"}>
                                                                        {
                                                                            project.details.map((detail, index) => {
                                                                                return (<li key={index}>{detail}</li>)
                                                                            })
                                                                        }
                                                                    </ul>
                                                            }
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                        );
                    })
                }
            </PageBox>
        </div>
    );
}

export default Experience;