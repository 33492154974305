import React from 'react';
import './HomeBtn.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons';

const HomeBtn = () =>
    <div className={"HomeBtn"}>
        <a href="/"><FontAwesomeIcon icon={faHome} /></a>
    </div>

export default HomeBtn;