import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import './PageBox.css';

const PageBox = (props) => {
    const [showId, setShowId] = useState(0);

    useEffect(() => {
        $(".PageBoxContent#content-" + showId).show();
        $(".PageBoxMenu #" + showId).addClass("selected");
    })

    function showPageBoxContent(e) {
        var id = e.target.id;
        if (id !== showId) {
            $(".PageBoxOption").removeClass("selected");
            $(".PageBoxMenu #" + id).addClass("selected");
            $(".PageBoxContentContainer .PageBoxContent").hide();
            setShowId(id);
        }
    }

    return (
        <div className={"PageBox"}>
            <div className={"PageBoxMenu"}>
                {
                    props.contents.map((content, index) => (
                        <div key={index} id={index} className={"PageBoxOption"} onClick={showPageBoxContent}>{content.name}</div>
                    ))
                }
            </div>
            <div className={"PageBoxContentContainer"}>
                { props.children }
            </div>
        </div>
    )
}

export default PageBox;