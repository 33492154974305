import React from 'react';
import './EmailBtn.css';

const EmailBtn = (props) =>
    <div className={"SendEmailBtn"}>
        <a href={"/"}>
            {props.children}
        </a>
    </div>

export default EmailBtn;