import React from 'react';
import './SocialMediaBar.css';
import SocialMediaBtn from '../SocialMediaBtn/SocialMediaBtn';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const SocialMediaBar = (props) =>
    <div className={props.additionalClass ? "SocialMediaBar " + props.additionalClass : "SocialMediaBar"}>
        {/*<div><SocialMediaBtn icon={faGithub} /></div>*/}
        <div><SocialMediaBtn icon={faLinkedin} url="https://www.linkedin.com/in/leolee810" /></div>
        <div><SocialMediaBtn icon={faInstagram} url="https://www.instagram.com/leoohyl/"/></div>
        <div><SocialMediaBtn icon={faFacebook} url="https://www.facebook.com/leo.lee.14224/"/></div>
        <div><SocialMediaBtn icon={faTwitter} url="https://twitter.com/Leoyin810"/></div>
        <div><SocialMediaBtn icon={faEnvelope} url="mailto:leoyin810@gmail.com"/></div>
    </div>

export default SocialMediaBar;